footer{
    padding-top: 5rem;
}
.custom-row{
    margin-top: 50px;
    display: flex;
}
.custom-row .custom-col{
    width: 125px;
}
footer h3{
    color: var(--white);
    font-size: 15px;
}
footer .ul{
    list-style: none;
    padding: 0;
}
footer .ul li{
    padding: 0.1rem 0;
}
footer .ul li a{
    text-decoration: none;
    color: var(--white);
    font-size: 16px;
    opacity: 0.5;
}
footer .ul li a:hover{
    color: var(--primary);
}
footer hr{
    color: var(--white);
    /* margin: 1rem 0.7rem; */
    margin: 1rem 0rem;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social-links{
    display: flex;
    list-style: none;
}
.social-links li{
    padding: 0 0.5rem;
}
.social-links li a{
    color: var(--white);
}
.social-links li a:hover{
    color: var(--primary);
}
.footer-bottom div p{
    font-size: 15px;
}
.btn-with-icon img {
    margin-right: 1rem;
    width: 21px;
    height: 5px;
}
.footer-active{
    opacity: 1 !important;
}
