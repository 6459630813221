/* Tablets */
@media  (max-width : 720px) {
    .custom-row .custom-col{
	margin-top: 5rem;
    }
    .banner-text{
	width: 100% !important;
	min-width: 100% !important;
    }
    .banner-img{
	width: 250px;
    }
    .banner-text h1{
	font-size: 80px;
	margin-bottom: 10rem;
    }
    .container {
	padding: 0 1.5rem;
    }
    footer hr{
	margin: 1rem 0 !important;
    }
    footer{
	padding-top: 130px !important;
    }
    .footer-bottom{
	flex-direction: column;
    }
    .social-links{
	order: 1;
	padding: 0;
	justify-content: space-between;
    }
    .social-links li a{
	font-size: 15px;
    }
    .footer-bottom div p{
	opacity: 0.6;
	font-size: 14px !important;
    }
    .footer-bottom div{
	order: 2;
    }
}


/* Tablets */
@media  (max-width : 1200px) {
    .container-fluid{
	padding: 0 2rem;
    }
    .normal-p.p-mt,.normal-p {
	width: 100%;
    }
    ul.normal-p{
	width: auto !important;
    }
}
