:root {
    --white: #FFF;
    --primary: #b1b1b1;
    --secondary: #e9e9e9;
    --gray: #3C3C3C;
}

@font-face {
    font-family: 'Pixeloperatormono';
    src: url('https://cdn.shopify.com/s/files/1/2642/6578/files/PPNeueBit-Bold.woff') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../font/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--gray);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--secondary);
}

body {
    background: #000000 0% 0% no-repeat padding-box;
    font-family: Poppins, ui-sans-serif;
}

.section {
    position: relative;
    width: 100%;
    /* overflow: hidden; */
    overflow-x: clip;
}

.banner-section {
    height: calc(100vh - 70.99px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.banner-text {
    text-align: center;
}

.banner-img {
    position: absolute;
    z-index: -5;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
    width: 240px;
}

.banner-text h1 {
    font-size: 130px;
    color: var(--white);
    font-family: Pixeloperatormono, sans-serif;
    line-height: 0.8;
    margin-top: 0;
    margin-bottom: 10rem;
    font-weight: 500;
}

.banner-text .flex-text p {
    color: var(--white);
    font-size: 16px;
}

.section-h2 {
    font-family: Pixeloperatormono, sans-serif;
    font-size: 56px;
    color: #d8d8d8;
    line-height: 0.9;
    width: 360px;
}
.section-h3 {
    font-family: Pixeloperatormono, sans-serif;
    color: var(--gray);
    font-size: 36px;
    margin-top: 1rem;
    margin-bottom: 16px;
}

.normal-p.p-mt,.normal-p{
    width: 510px;
    line-height: 1.7;
}

.grey-p {
    color: var(--gray);
}

.light-p {
    color: #d8d8d8;
}

.contribute {
    margin-top: 2rem;
}

.normal-btn-with-icon {
    color: var(--white);
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: none !important;
    color: var(--white) !important;
    border: none;
    padding: 0;
    margin-top: 2.5rem !important;
}

.normal-btn-with-icon:hover {
    color: var(--white);
}

.normal-btn-with-icon img {
    margin-left: 1rem;
    width: 21px;
    height: 5px;
}

.daul-flex h2 {
    display: inline-block;
    margin: 0;
}

.daul-flex sub {
    color: var(--white);
}

.normal-p {
    font-size: 16px;
}

.p-mt{
    margin-top: 2rem;
}

.mac .banner-text h1{
    line-height: 0.1 !important;
}
