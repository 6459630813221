.info-section {
    position: relative;
    height: 720px;
}
.brick-layout {
    position: absolute;
    width: 60%;
    height: 100%;
    top: 0;
    right: -150px;
    display: flex;
    column-gap: 15px;
    overflow: hidden;
    z-index: 1;
    object-fit: cover;
    opacity: .4;
}
.brick-layout::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--dark-900);
    opacity: .4;
}
.brick-column {
    width: 100%;
    height: 100%;
}
.brick-column:first-child img:first-child {
    margin-top: -220px;
}
.brick-column:nth-child(2) img:first-child {
    margin-top: -130px;
}
.brick-column:nth-child(2) img:nth-child(2) {
    height: 400px;
}
.brick-column:nth-child(3) img:first-child {
    margin-top: -240px;
}
.brick-column img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 15px;
    border-radius: 10px;
}
.text-overlay {
    position: relative;
    padding-top: 24px;
    z-index: 2; /* Ensure text appears above the image */
}
@media screen and (max-width: 1100px) {
    .brick-layout {
	width: 70%;
    }
}
@media screen and (max-width: 960px) {
    .brick-layout {
	width: 80%;
    }
}
@media screen and (max-width: 840px) {
    .brick-layout {
	display: flex;
	right: 50%;
	transform: translate(50%, -55%);
	top: 58%;
	border-radius: 10px;
    }
    .brick-layout::before {
	opacity: .2;
    }
}
@media screen and (max-width: 700px) {
    .brick-layout {
	border-radius: 0;
	width: 100%;
	opacity: .2;
	display: flex;
	justify-content: center;
	align-items: center;
    }
}
